<template>
  <v-dialog v-model="logoutActive" width="500" persistent>
    <v-card :dark="semiTransparent" :style="semiTransparent ? 'background-color:rgba(120,120,120,0.4);transition:background-color 1s' : 'background-color:rgba(255,255,255,1)'" @mouseover.native="resetTimeout">
      <v-card-title class="headline" primary-title>
        <img :src="require('@/assets/shield.svg')" style="margin-right:1em;" contain height="50" alt="Covenant Shield Logo" />
        Scots Portal: Logged Out
      </v-card-title>
      <v-card-text>
        <p>You have been logged out of the Portal. Click the button below to login.</p>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" size="medium" @click="doLogin">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  props: {
    messages: Array
  },
  setup (props, { root }) {
    const loginUrl = ref(process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '') + '/saml')
    const logoutActive = computed(() => !root.$store.state.loggedIn)
    const user = computed(() => root.$feathers.get('user'))

    const semiTransparent = ref(false)
    const timeout = ref(null)

    watch(logoutActive, () => {
      if (logoutActive.value === true) {
        resetTimeout()
      } else {
        timeout.value = null
        semiTransparent.value = false
      }
    })

    function hasClass (el, className) {
      let arr = el.classList
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === className) return true
      }
      return false
    }

    function resetTimeout () {
      semiTransparent.value = false
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        let el = document.activeElement
        if (el != null) {
          while (document.activeElement.tagName !== 'BODY' && el != null) {
            if (hasClass(el, 'v-card')) {
              resetTimeout()
              return
            }
            el = el.parentElement
          }
        }
        semiTransparent.value = true
      }, 5000)
    }

    function doLogin () {
      window.localStorage.setItem('redirectPath', root.$route.path)
      const basePath = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH.replace('/socket.io', '')
      window.location = basePath + '/saml'
    }

    return {
      loginUrl,
      logoutActive,
      user,
      semiTransparent,
      timeout,
      resetTimeout,
      doLogin
    }
  }
}
</script>
