<template>
  <v-navigation-drawer v-model="active" :clipped="true" :expand-on-hover="miniMenu && !isSmall" app overflow disable-resize-watcher disable-route-watcher dark>
    <v-img :src="image" height="100%">
      <v-layout fill-height>
        <v-list fill-height style="width:100%;height:100%;overflow-y:auto;">
          <template v-for="item in menu">
            <list-group v-if="item.children && item.title" :key="item.title" :item="item"></list-group>
            <list-item v-else-if="item.title" :key="item.title" :item="item"></list-item>
          </template>
        </v-list>
      </v-layout>
    </v-img>
  </v-navigation-drawer>
</template>
<style>
.v-navigation-drawer .v-image .layout {
  background: rgba(27, 27, 27, .64);
}
.v-navigation-drawer .v-image .layout .v-list {
  background: none;
}
.v-navigation-drawer.theme--dark .v-image .layout .v-list .primary--text {
  color: #C3DFF8 !important;
  caret-color: #C3DFF8 !important;
}
.v-navigation-drawer .v-image .layout .v-list .v-list-item .v-list-item__icon i {
  max-width: 24px;
}
.v-navigation-drawer .v-image .layout .v-list .v-list-item .v-list-item__icon i.fas.fa-bookmark {
  margin-left: 3px;
}
.v-navigation-drawer .v-image .layout .v-list .v-list-item .v-list-item__icon i.fas.fa-user-graduate {
  margin-left: 2px;
}
</style>
<script>
import { reactive, computed, onMounted, watch } from '@vue/composition-api'
import buildMenu from '../../helpers/menuRevised.js'
// import paths from '../../router/paths'

export default {
  components: {
    listGroup: () => import('./sidenav/listGroup'),
    listItem: () => import('./sidenav/listItem')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const active = computed({
      get: () => root.$store.state.sideNavActive,
      set: (val) => root.$store.commit('setSideNavActive', val) })
    const image = computed(() => '/img/sidebar/photo0' + ((Math.floor(Math.random() * 100) % 8) + 1) + '.jpg')
    const isLoggedIn = computed(() => root.$store.state.loggedIn)
    const menu = reactive(buildMenu(isLoggedIn.value, user.value.roles || [], root.$route.path))
    const miniMenu = computed(() => ('settings' in root.$store.state.user && 'miniMenu' in root.$store.state.user.settings) ? root.$store.state.user.settings.miniMenu : false)

    // breakpoint names are xs, sm, md, lg, xl; only xs and sm are small
    const isSmall = computed(() => root.$vuetify.breakpoint.name === 'xs' || root.$vuetify.breakpoint.name === 'sm' || root.$vuetify.breakpoint.name === 'md')

    onMounted(() => {
      active.value = !isSmall.value
    })

    watch(() => root.$route, () => {
      if (isSmall.value) active.value = false
    })

    return {
      user,
      active,
      menu,
      image,
      isLoggedIn,
      miniMenu,
      isSmall
    }
  }
}
</script>
