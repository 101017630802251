<template>
  <v-main ref="viewContainer" :style="hasMiniMenu ? 'padding-left: 56px' : ''">
    <transition name="fadeEnlarge" mode="out-in">
      <router-view />
    </transition>
    <transition name="fadeEnlarge">
      <v-btn v-if="fabActive" fab fixed bottom right color="primary" :to="fabLink">
        <v-icon>{{ fabIcon }}</v-icon>
      </v-btn>
    </transition>
    <v-snackbar v-model="snackbarActive" top right :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="dispatchSnackbarAction" v-bind="attrs">{{ snackbarActionText }}</v-btn>
      </template>
    </v-snackbar>
  </v-main>
</template>
<style>
div.v-application.theme--light main.v-main {
  background-color: #dcdcdc;
}
.fadeEnlarge-enter {
  opacity: 0;
  transform: translateX(-5%)
}
.fadeEnlarge-enter-to, .fadeEnlarge-leave-from {
  opacity: 1;
  transform: translateX(0)
}
.fadeEnlarge-enter-active, .fadeEnlarge-leave-active {
  transition: all .2s ease-in-out;
}
.fadeEnlarge-leave-to {
  opacity: 0;
  transform: translateX(-5%)
}
.slide-enter {
  opacity: 0;
  transform: translateX(100%);
  position:absolute;
}
.slide-enter-to, .slide-leave-from {
  opacity: 1;
  transform: translateX(0);
}
.slide-enter-active, .slide-leave-active {
  transition: all .3s ease-in-out;
}
.slide-leave-to {
  position:absolute;
  opacity: 0;
  transform: translateX(-100%)
}
</style>
<script>
import { computed } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const sideNavActive = computed({
      get: () => root.$store.state.sideNavActive,
      set: (val) => root.$store.commit('setSideNavActive', val)
    })
    const hasMiniMenu = computed(() => sideNavActive.value && root.$store.state.settings.miniMenu && (root.$vuetify.breakpoint.name === 'lg' || root.$vuetify.breakpoint.name === 'xl'))

    return {
      sideNavActive,
      hasMiniMenu
    }
  },
  data: () => ({
    fabActive: false,
    fabIcon: '',
    fabLink: ''
  }),
  computed: {
    snackbar: {
      get () {
        return this.$store.state.main.snackbar
      },
      set (val) {
        this.$store.dispatch('main/snackbar', val)
      }
    },
    snackbarActive: {
      get () {
        return this.snackbar.active
      },
      set (val) {
        this.snackbar.active = val
      }
    },
    snackbarActionActive () {
      return this.snackbar.action !== '' && this.snackbar.actionText !== ''
    },
    snackbarActionText () {
      if ('actionText' in this.snackbar && this.snackbar.actionText !== '' && 'action' in this.snackbar && this.snackbar.action !== '') {
        return this.snackbar.actionText
      } else {
        return 'Dismiss'
      }
    }
  },
  watch: {
    $route (to, from) {
      this.checkFAB()
    }
  },
  methods: {
    checkFAB () {
      let path = this.$route.path
      if (path === '/query') {
        this.fabActive = true
        this.fabIcon = 'fal fa-plus'
        this.fabLink = '/query/new'
      } else {
        this.fabActive = false
        this.fabIcon = ''
        this.fabLink = ''
      }
    },
    dispatchSnackbarAction () {
      if ('action' in this.snackbar && 'actionText' in this.snackbar && this.snackbar.action !== '' && this.snackbar.actionText !== '') {
        if (this.snackbar.action.substr(0, 3) === 'to:') {
          this.$router.push(this.snackbar.action.substr(3))
        } else if (this.snackbar.action.substr(0, 7) === 'http://' || this.snackbar.action.substr(0, 8) === 'https://') {
          window.open(this.snackbar.action, this.snackbar.actionText)
        } else {
          this.$store.dispatch(this.snackbar.action)
        }
      }
      this.$store.dispatch('main/snackbar', { active: false })
    }
  },
  mounted () {
    if (this.$refs.viewContainer.childElementCount === 0) {
      this.$router.push('/' + document.location.href.substring(8).split('/').splice(1).join('/'))
    }
    this.checkFAB()
  }
}
</script>
