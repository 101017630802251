import paths from '../router/paths'

function hasRole (userRoles, roles) {
  if (roles == null || roles.length === 0) return true
  for (let i = 0; i < roles.length; i++) {
    if (userRoles.filter((role) => role === roles[i]).length > 0) return true
  }
  return false
}

export default function buildMenu (isLoggedIn, userRoles, route) {
  const base = [
    { path: '/', title: 'Dashboard', icon: 'fas fa-home', loginRequired: true },
    {
      title: 'Banner',
      icon: 'fas fa-bookmark',
      children: [
        { href: 'https://bannerweb.covenant.edu:8443/EmployeeSelfService', title: 'Employee', actionIcon: 'fal fa-lock' },
        { href: 'https://bannerweb.covenant.edu:8443/StudentSelfService/ssb/studentProfile', title: 'Student', actionIcon: 'fal fa-lock' }
      ]
    },
    { title: 'Board Files', icon: 'fas fa-folder-tree', loginRequired: true, basePath: '/board', roles: ['Technology Services', 'Presidents Office Staff'], hidden: true },
    { title: 'Calendar', icon: 'fas fa-calendar', loginRequired: true, basePath: '/calendar', roles: ['Employee'] },
    { title: 'Chapel', icon: 'fas fa-church', basePath: '/chapel', hidden: false },
    { title: 'Check-In', icon: 'fas fa-check-circle', basePath: '/checkin', hidden: true },
    { title: 'Cov-Cares', icon: 'fas fa-heart', basePath: '/cov-cares' },
    // { title: 'Discussions', icon: 'fas fa-dungeon', loginRequired: true, basePath: '/discussions' },
    // { title: 'Events', icon: 'fas fa-calendar-alt', loginRequired: true, basePath: '/event' },
    { title: 'Forms', icon: 'fas fa-file-alt', basePath: '/forms' },
    { title: 'GreatScots', icon: 'fas fa-id-card', loginRequired: true, basePath: '/greatscots', hidden: false },
    { title: 'Library', icon: 'fas fa-books', loginRequired: false, basePath: '/library', hidden: false },
    { title: 'Mailroom', icon: 'fas fa-mailbox', loginRequired: true, basePath: '/mailroom', hidden: false },
    { title: 'RideShare', icon: 'fas fa-car', loginRequired: true, basePath: '/ride-share', hidden: false },
    { title: 'ScotsWork', icon: 'fas fa-briefcase', loginRequired: true, basePath: '/scotswork' },
    { title: 'Student', icon: 'fas fa-user-graduate', loginRequired: true, basePath: '/student' },
    { title: 'Tech', icon: 'fas fa-phone-laptop', loginRequired: true, basePath: '/tech', roles: ['Technology Services', 'Technology Services 1'] }
  ]

  let menu = []

  for (const item of base) {
    const { title, icon, loginRequired, basePath, roles, hidden } = item
    if (basePath) {
      // If this section is hidden and the path they are on is not in that section, then skip it
      if (hidden && !route.startsWith(basePath)) continue
      // If this section requires a login and they are not logged in, add the lock icon and don't add the children
      if (!isLoggedIn && loginRequired) {
        // If roles are specified for access, then do not add the section since they are not logged in
        if (roles == null) menu.push({ title, icon, basePath, loginRequired: true })
        continue
      }
      // Find the paths which start with this base path; those are all of the ones that might be in this section
      // Also only include the paths which are stated to be included in the menu
      item.children = paths.filter(({ path, includeInMenu }) => path.startsWith(basePath) && includeInMenu)
      // Include the link if the roles is not set, or if the user is logged in and has one of the roles
        .filter(({ roles }) => !roles || (isLoggedIn && hasRole(userRoles, roles)))
      // If they are not logged in but the child path does require login, add the lock icon
        .map((row) => {
          if (row.loginRequired && !isLoggedIn) return { ...row, actionIcon: 'fal fa-lock' }
          return row
        })
      // The title is also the page title, and might include the section name; if so, remove the section name for the menu
        .map((row) => {
          let childTitle = row.title
          if (childTitle.startsWith(title)) childTitle = childTitle.substring(title.length)
          if (childTitle.startsWith(':')) childTitle = childTitle.substring(1)
          return { ...row, title: childTitle.trim() }
        })
      if (item.children.length === 0) continue
      if (route.startsWith(basePath)) {
        item.expanded = true
      }
    }
    menu.push(item)
  }

  return menu
}
